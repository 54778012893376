<template>
  <router-view />
</template>

<script>
export default {
  mounted () {
    console.log('Testing View');
  },
}
</script>

<style scoped>

</style>
